import * as React from "react";
import { useState, useEffect, useMemo } from "react";



const usePopularConversations = (queryString) => {

  // const parsedTitle = JSON.stringify(encodedTitle)

  // const encodedTitle = title && encodeURI(title)

  const [results, setResults] = useState();
  const [errors, setErrors] = useState();


  //var myHeaders = new Headers()

  //myHeaders.append("Authorization", "Bearer 5e947acd-45b0-4a2a-8a56-1d801ad2e5b8")
  //myHeaders.append("Accept", "application/json")


  //var requestOptions ={
  //  method: 'GET',
  //  headers: myHeaders,
  //}
  var url = process.env.GATSBY_NODEBB_URL;


  useEffect(() => {
    fetch(`${url}search?${queryString}&_uid=1`, {
      method: 'GET',
      headers: {
        Accept: 'application/json', Authorization: "Bearer " + process.env.NODEBB_MASTER_API_TOKEN
      }
    })
      .then(async (response) => {
        if (response.ok) {
          setResults(await response.json());
          setErrors(null);
        } else {
          setErrors(await response.text());
        }
      })
      .catch((err) => {
        setErrors(err.message);
      });
  }, [queryString]);

  return [results, errors]

};

export default usePopularConversations;
